.badge,.tag {
    @apply inline-flex items-center justify-center px-2 py-1.5
     text-xs font-inter tracking-wide align-baseline transition-all duration-200
     leading-none rounded font-medium
}

.tag{
    @apply cursor-pointer
}

.shift-badge {
    @apply inline-flex text-left p-1.5 text-xs font-inter transition-all duration-200 
        rounded font-medium text-white shadow-soft shadow-slate-200/50 w-full mt-2 truncate
}