.pagination {
  @apply space-x-1.5;
}
.pagination .prev {
  @apply rounded-l-lg;
}

.pagination .next {
  @apply rounded-r-lg;
}

.pagination .page {
  @apply bg-slate-150 dark:bg-navy-500 flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-slate-150 px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:bg-navy-500 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90;
}

.pagination .page.active {
  @apply bg-primary text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90;
}
