.datagrid {
  @apply w-full text-left mt-2;
}

.datagrid th {
  @apply whitespace-normal bg-slate-200 px-3 py-2 uppercase font-normal text-xs+ text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-4;
}

.datagrid-row-non-bordered tr {
  @apply !rounded-l-none !rounded-r-none;
}

.datagrid-row-non-bordered th:first-child {
  @apply !rounded-l-none !rounded-r-none;
}

.datagrid-row-non-bordered th:last-child {
  @apply !rounded-l-none !rounded-r-none;
}

.datagrid th:first-child {
  @apply rounded-l-lg;
}

.datagrid th:last-child {
  @apply rounded-r-lg;
}

.datagrid td {
  @apply whitespace-normal px-3 py-2 sm:px-5 text-xs+;
}

.datagrid td:first-child {
  @apply rounded-l-lg;
}

.datagrid td:last-child {
  @apply rounded-r-lg;
}

.datagrid > tbody > tr:has(> td .deactive_user) {
  @apply !bg-red-50;
}

.datagrid > tbody > tr:nth-child(2n):not(:has(> td.deactive_user)) {
  @apply bg-[#f1f5f9];
}

.datagrid-nopadding td {
  @apply px-1;
}
.datagrid-nopadding th {
  @apply px-2;
}

.status-tab {
  @apply btn shrink-0 px-3 py-1 text-xs+ font-medium hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100;
}

.active-status-tab {
  @apply bg-white shadow dark:bg-navy-500 dark:text-navy-100;
}

.datagrid-col-right {
  @apply text-right;
}

.datagrid-col-left {
  @apply text-left;
}

.custom-datagrid {
  @apply table w-full border-collapse my-2;
}

.datagrid-header {
  @apply table-header-group;
}

.datagrid-header-row {
  @apply !bg-slate-200 uppercase;
}

.datagrid-body {
  @apply table-row-group;
}

.datagrid-row {
  @apply table-row;
}

.datagrid-cell {
  @apply table-cell px-6 py-2 text-xs+ align-middle;
}

.datagrid-cell-compact {
  @apply px-2;
}

.datagrid-header-cell {
  @apply table-cell px-6 py-2 text-[#3e485a] align-middle;
}

.datagrid-cell-compact {
  @apply px-2;
}

.datagrid-row:nth-child(odd) {
  @apply bg-[#f8fafc];
}

.datagrid-row:nth-child(even) {
  @apply bg-[#f1f5f9];
}

.noresults {
  @apply text-center p-5;
}

.shift-planning-cell {
  @apply table-cell border-b border-black text-center p-2 align-middle;
}

.shift-planning-cell:not(:last-child) {
  @apply border-r;
}

.shift-planning-header-cell {
  @apply shift-planning-cell max-w-min w-2;
}

.shift-planning-summary-cell {
  @apply shift-planning-cell h-40 min-h-[40px];
}

.table-row:last-child .shift-planning-summary-cell {
  @apply border-b-0;
}
