.simple-calendar table {}

.simple-calendar tr {}

.simple-calendar th {}

.simple-calendar td {}

.simple-calendar .calendar-heading nav {}

.simple-calendar .calendar-heading nav .previous {}

.simple-calendar .calendar-heading nav .today {}

.simple-calendar .calendar-heading nav .next {}

.simple-calendar .day {}

.simple-calendar .today {
    @apply bg-blue-300;
}

.simple-calendar .wday-1 {}
.simple-calendar .wday-2 {}
.simple-calendar .wday-3 {}
.simple-calendar .wday-4 {}
.simple-calendar .wday-5 {}

/* weekends */
.simple-calendar .wday-6 {
    @apply bg-red-200;
}
.simple-calendar .wday-0 {
    @apply bg-red-200;
}

.simple-calendar .past {}
.simple-calendar .future {}

.simple-calendar .start-date {}

.simple-calendar .prev-month {}
.simple-calendar .next-month {}
.simple-calendar .current-month {}

.simple-calendar .has-events {
    @apply bg-green-300;
}