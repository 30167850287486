@import "tom-select/dist/css/tom-select.css";

.ts-control {
  @apply rounded-lg border-slate-300 px-3 py-2 font-inter font-medium outline-none
    transition duration-300 ease-in-out hover:border-slate-400 dark:border-navy-450
    dark:bg-navy-700 dark:text-navy-100 dark:hover:border-navy-400;
}

.input-active .ts-control {
  @apply border-primary dark:border-accent;
}

.ts-dropdown,
.ts-control,
.ts-control input {
  @apply text-sm leading-normal placeholder:text-slate-400/70 text-slate-600 dark:text-navy-100;
}

.ts-wrapper.multi .ts-control > div {
  @apply rounded bg-slate-150 text-slate-800 dark:bg-navy-500 dark:text-navy-50;
}

.ts-dropdown{
 @apply rounded-md overflow-hidden shadow-none dark:bg-navy-700 border-slate-150 dark:border-navy-600
}

.ts-dropdown .create{
  @apply text-slate-600 dark:text-navy-100
}

.ts-dropdown .create:hover, .ts-dropdown .option:hover, .ts-dropdown .active{
    @apply text-white bg-primary dark:bg-accent 
}

.ts-dropdown .create:hover.create, .ts-dropdown .option:hover.create, .ts-dropdown .active.create{
 @apply text-white
}

.ts-wrapper.plugin-remove_button .item .remove{
  @apply border-slate-300 dark:border-navy-400
}

.ts-control, .ts-wrapper.single.input-active .ts-control{
  @apply bg-transparent
}

.ts-wrapper.single .ts-control, .ts-wrapper.single.input-active .ts-control {
  @apply bg-no-repeat bg-center pr-9;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewbox='0 0 20 20'%3e%3cpath stroke='%235C6B8A' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-size: 1.5em 1.5em;
  print-print-color-adjust: exact;
}

.ts-wrapper.unselectable.full .ts-control {
  @apply !bg-zinc-100 !pointer-events-none;
}

.ts-wrapper.unselectable.single .ts-control {
  @apply !bg-zinc-100 !pointer-events-none;
}