@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


@import "lineone/base.css";
@import "lineone/components.css";
@import "lineone/pages.css";

@import "./dropzone.css";
@import "./table.css";
@import "./pagination.css";
@import "./typography.css";
@import "./form_input.css";
@import "./notification.css";
@import "./simple_calendar.css";
@import "./navbar.css";
@import './tabulator.css';

input:disabled {
  @apply pointer-events-none;
}

@layer components {
  .is-active > .tree-header > .ac-trigger > .ac-icon {
    @apply rotate-90;
  }
  .is-active > .tree-header {
    @apply text-slate-800;
  }
  .dark .is-active > .tree-header {
    @apply text-navy-100;
  }
}

.accordion {
  @apply [&.is-active_.ac-trigger]:font-semibold [&.is-active_.ac-trigger]:text-slate-800 dark:[&.is-active_.ac-trigger]:text-navy-50 [&.is-active_svg]:rotate-90;
}

.accordion-link {
  @apply flex w-full items-center justify-between py-2 text-xs+ tracking-wide text-slate-600 outline-none transition-[color,padding-left] duration-300 ease-in-out hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50;
}