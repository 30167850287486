@import 'tabulator-tables/dist/css/tabulator_bootstrap5.css';

.tabulator {
  @apply text-left mt-2;
}


/* loader */

.tabulator .tabulator-alert {
  @apply !bg-[#b7b7b766];
}

.tabulator .tabulator-alert .tabulator-alert-msg {
  @apply !bg-transparent !border-none;
}

.tabulator-spinner {
  @apply spinner w-12 h-12 animate-spin rounded-full border-[3px] border-indigo-700 border-r-transparent;
}

/* headers */

.tabulator .tabulator-header {
  @apply !bg-slate-200 uppercase font-normal text-xs+ text-slate-800;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  @apply whitespace-normal;
}

.tabulator .tabulator-header .tabulator-col {
  @apply !bg-slate-200;
}

.tabulator-header .tabulator-header-filter input {
  @apply w-24 h-9 !pl-2 pr-1 py-2 border border-slate-300 rounded-md !text-sm box-border placeholder:text-sm placeholder:pl-1;
}

.tabulator-header-search-icon {
  @apply absolute right-6 top-1/2 -translate-y-1/2;
}

/* rows */

.tabulator .tabulator-table {
  @apply w-full;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row {
  @apply text-xs+ !border-none;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-cell {
  @apply bg-[#f8fafc];
}

.tabulator .tabulator-row.tabulator-row-even .tabulator-cell {
  @apply !bg-[#f1f5f9]
}

/* footer */

.tabulator .tabulator-footer {
  @apply bg-[#f8fafc];
}

.tabulator-footer-contents {
  @apply relative flex w-full items-center;
}

.tabulator .tabulator-footer .tabulator-page-size {
  @apply absolute left-0 text-xs+ font-normal form-select rounded-full border border-slate-300 bg-white px-2 py-1 pr-6;
}

.tabulator .tabulator-footer .tabulator-paginator label {
  @apply absolute left-16 mt-1 text-xs+ font-normal;
}

.tabulator .tabulator-footer .tabulator-page-counter {
  @apply text-xs+ absolute right-0;
}

.tabulator .tabulator-footer .tabulator-paginator {
  @apply text-center;
}

.tabulator .tabulator-footer .tabulator-page[data-page="first"] {
  @apply hidden;
}

.tabulator .tabulator-footer .tabulator-page[data-page="last"] {
  @apply hidden;
}

.tabulator .tabulator-footer .tabulator-page {
  @apply bg-slate-150 h-8 min-w-[2rem] mr-1 rounded-lg bg-slate-150 px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80;
}

.tabulator .tabulator-footer .tabulator-page.active {
  @apply text-white;
}