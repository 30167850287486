/* TDDO Please move to directories */

/* Remove arrows */
.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.remove-arrow {
    -moz-appearance: textfield;
}

.text-input {
    @apply form-input w-full rounded-lg border border-slate-300 px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent;
}

.default-select {
    @apply form-select w-full rounded-lg border border-slate-300 px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent;
}

.text-input-padding {
    @apply form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent;
}

.input-icon {
    @apply pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent;
}

.button-cancel {
    @apply btn min-w-[7rem] !rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90 disabled:bg-slate-300;
}

.button-cancel-rectangle {
    @apply btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90;
}

.button-delete {
   @apply btn min-w-[7rem] !rounded-full bg-red-500 font-medium text-white focus:bg-primary-focus;
}

.button-submit {
   @apply btn min-w-[7rem] !rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 disabled:bg-slate-300;
}

.button-success {
    @apply btn space-x-2 bg-success font-medium text-white hover:bg-success-focus hover:shadow-lg hover:shadow-success/50 focus:bg-success-focus focus:shadow-lg focus:shadow-success/50;
}

.button-error {
    @apply ml-4 btn space-x-2 bg-error font-medium text-white hover:bg-error-focus hover:shadow-lg hover:shadow-error/50 focus:bg-error-focus focus:shadow-lg focus:shadow-error/50;
}

.link-to-export {
    @apply flex h-8 items-center px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100;
}

.link-to-add-model {
    @apply btn bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90
}

.button-slate {
    @apply btn space-x-2 bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90 disabled:bg-slate-300;
}

.button-primary {
    @apply btn bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 disabled:bg-slate-300;
}

.checkbox-primary {
    @apply is-basic h-5 w-5 rounded border-slate-400/70 checked:bg-primary checked:border-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:checked:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent;
}

.checkbox-primary-rounded {
    @apply form-checkbox is-basic h-5 w-5 rounded-full border-slate-400/70 checked:border-primary checked:bg-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:checked:border-accent dark:checked:bg-accent dark:hover:border-accent dark:focus:border-accent;
}

.checkbox-primary-rounded:checked::before { 
    @apply absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] w-2 h-2 rounded-full bg-white;
}

.checkbox-success {
    @apply is-basic h-5 w-5 rounded border-slate-400/70 checked:bg-success checked:border-success hover:border-success focus:border-success dark:border-navy-400 dark:checked:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent;
}

.switch-primary {
    @apply form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white;
}

.form-input-icon {
    @apply pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent;
}

.popper-link {
    @apply flex h-8 items-center px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100;
}

.input-disabled {
    @apply pointer-events-none select-none border-none bg-zinc-100 dark:bg-navy-600;
}

.qr-scanner-input {
    @apply focus:outline-none caret-transparent pointer-events-none text-white sr-only;
}

.checkbox-input {
    @apply form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:!bg-success checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:before:bg-white;
}

.search-input {
    @apply max-w-xs form-input h-9 w-full rounded-lg bg-slate-150 px-3 py-2 pr-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring;
}

.icon-button {
    @apply btn !h-8 !w-8 !rounded-full !p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 !ml-4 !mt-px;
}

.close-button {
    @apply btn -mr-1.5 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25;
}

.lot-selection-input {
    @apply !w-full text-input bg-transparent disabled:pointer-events-none disabled:select-none disabled:border-none disabled:bg-zinc-100 remove-arrow;
}

.input-selected {
    @apply w-[85px] h-6 p-2 rounded-[.3rem] box-border border border-[rgb(203,213,225)];
}

.input-deselected {
    @apply w-[85px] h-6 p-2 box-border bg-white border-0;
}

.text-area {
    @apply form-textarea w-full resize-none rounded-lg border border-slate-300 p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary;
}