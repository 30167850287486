#notification-ref {
    @apply btn relative !block !h-8 !w-8 !rounded-full !p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25;
}

#notification-ref svg {
    @apply w-full;
}

.wrapper {
    @apply popper-box mx-4 mt-1 flex max-h-[calc(100vh-6rem)] w-[calc(100vw-2rem)] flex-col rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-800 dark:bg-navy-700 dark:shadow-soft-dark sm:m-0 sm:w-80;
}

.tab-btn {
    @apply btn shrink-0 !rounded-none border-b-2 px-3.5 py-2.5;
}

.tab-passive {
    @apply border-primary dark:border-accent text-primary dark:text-accent-light;
}

.tab-content-common {
    @apply tab-shift-left is-scrollbar-hidden space-y-4 overflow-y-auto px-4 py-4;
}

.tab-default {
    @apply border-transparent hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100;
}

.tab-card-wrapper {
    @apply rounded-t-lg bg-slate-100 text-slate-600 dark:bg-navy-800 dark:text-navy-200;
}